
  import AddressBook from '@/views/admin/basic/address_book/simple.vue';
  import {
    UploadOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
  } from '@ant-design/icons-vue';
  import _ from "lodash";
  import address from '@/utils/address';
  import apiCancel from "@/utils/api-cancel";
  import AnalyzePostalCode from "@/components/Address/hook/analyze-postalcode.js"
  import cachePostCode from "@/views/admin/order/hook/cachePostCode"
  import itemHook from "@/views/admin/order/hook/itemHook"
  import AddressGroup from "./components/address-group.vue"
  import AddressForm from "./components/address-form.vue"
  import {
    isValidate
  } from "./hook/order-validate"
  import PackageUpload from "./components/package-upload.vue"
  import {
    computed
  } from "vue"
  import moment from "moment"

  let test_rates_post = new apiCancel();

  export default {
    name: "AdminOrderRates",
    components: {
      UploadOutlined,
      PlusCircleOutlined,
      MinusCircleOutlined,
      AddressBook,
      AddressGroup,
      AddressForm,
      PackageUpload
    },
    provide() {
      return {
        packages: computed({
          get: () => {
            return this.form.packages
          },
          set: (val) => {
            this.form.packages = val
          }
        }),
        test_rates: this.test_rates
      }
    },
    data() {
      return {
        // 是否为修改状态
        isUpdate: false,
        // 运输方式
        shipmethods: [],
        // Freight Class
        freight_class: [],
        // 显示地址簿
        showBook: false,
        // 测试费用加载状态
        rates_loading: false,
        //
        rates_timer: 0,
        // 测算费用结果
        rates_result: {},
        // 当前选择的渠道
        rates_current_ship_method_id: 0,
        // 错误代码
        error_code: '',
        // 提交加载状态
        submit_loading: false,
        // 加载状态
        loading: false,
        // 查询地址加载状态
        query_address_loading: false,
        // 自定义显示内容
        custom_content: '',
        // 监听器控制
        stop: null,
        // 焦点事件控制
        onFoucsControl: false,
        // 打开导入包裹
        showImportPackage: false,
        // 发货地址是否隐藏
        shipper: true,
        // 币种
        currency: {},
      };
    },
    watch: {
      'form.global_reference1': {
        handler(newVal, oldVal) {
          for (var i in this.packages) {
            if (!this.packages[i].reference1 || this.packages[i].reference1 === oldVal) {
              this.packages[i].reference1 = newVal;
            }
          }
        }
      },
      'form.global_reference2': {
        handler(newVal, oldVal) {
          for (var i in this.packages) {
            if (!this.packages[i].reference2 || this.packages[i].reference2 === oldVal) {
              this.packages[i].reference2 = newVal;
            }
          }
        }
      },
      'form.address': {
        handler() {
          this.onFoucsControl = true;
        },
        immediate: true,
        deep: true
      },
      'form.address.name': {
        handler() {
          if (/^[A-Z0-9]{4}$/.test(this.address.name)) {
            //自动填充FBA地址
            this.queryFbaAddress(this.address.name);
            this.stop && this.stop();
          }
        }
      },
      'form.packages': {
        handler() {
          this.onFoucsControl = true;
          this.$nextTick(() => {
            let els = document.querySelectorAll('input');
            for (let i = 0; i < els.length; i++) {
              const element = els[i];
              element.addEventListener('change', () => {
                if (isValidate(this.$refs.address_form.getFieldsValue(), this.rules) && !this
                  .validate_package) {
                  this.test_rates()
                }
              }, true)

            }
          })
        },
        immediate: true,
        deep: true
      },
      rates_current_ship_method_id: {
        handler(newVal, oldVal) {

          if (oldVal && newVal !== this.rates_result.ship_method_id) {
            this.onFoucsControl = true;
            this.test_rates()
          }
        }
      },
      items: {
        handler(val) {
          val.forEach(item => {
            const {
              unit: {
                declared_value_currency,
                manufacture_country_code
              }
            } = item;

            Object.assign(item, {
              declared_value_currency,
              manufacture_country_code: this.$setting.env == 'mingda.lbl.morelink56.com' ? item
                .manufacture_country_code : manufacture_country_code,
            });
          })
        },
        deep: true
      }
    },
    computed: {
      packages() {
        return this.form.packages;
      },
      extra_packages() {
        let result = {};

        this.packages.forEach((item, index) => {
          for (var k in item) {
            result[`${index}.${k}`] = `${item[k]}`;
          }
        });

        return result;
      },
      ship_methods_result() {
        return (this.rates_result.ship_methods || []).sort(this.sort_rates);
      },
      charges() {
        return this.rates_result.charges || [];
      },
      total_cahrge() {
        var value = 0;

        this.charges.forEach(a => value += a.discount_value);

        return value.toFixed(2);
      },
      table_shipmethods() {
        return [{
            title: this.$t('承运商'),
            dataIndex: 'carrier_code',
            width: 80
          },
          {
            title: this.$t('渠道名称'),
            dataIndex: 'name',
            width: 250
          },
          {
            title: this.$t('运输时效'),
            dataIndex: 'again',
            width: 120
          },
          {
            title: this.$t('描述'),
            dataIndex: 'description',
            className: "desc-max-width",
            slots: {
              customRender: 'desc'
            }
          },
          {
            title: this.$t('燃油附加费'),
            dataIndex: 'fuel_surcharges',
            width: 100,
            align: 'center',
            customRender: ({
              text
            }) => `${(text * 100).toFixed(2)}%`
          },
          {
            title: this.$t('重量(Lbs)'),
            dataIndex: 'total_weight',
            width: 100,
            align: 'center',
            customRender: ({
              record
            }) => {
              return `${record.total_weight} ${this.renderUnit(this.obj_all_unit, 'weight_unit')}`
            }
          },
          {
            title: this.$t('单价'),
            dataIndex: 'unitPrice',
            width: 100,
            align: 'center',
            customRender: ({
              record
            }) => {
              return `${((record.amount / record.total_weight)*this.currency.exchange_rate).toFixed(2)} RMB`
            }
          },
          {
            title: this.$t('费用'),
            dataIndex: 'amount',
            width: 100,
            align: 'center',
            customRender: ({
              text,
              record
            }) => {
              return text + record.currency
            }
          },
          {
            title: this.$t('操作'),
            key: 'action',
            width: 100,
            align: 'center',
            fixed: "right",
            slots: {
              customRender: 'action'
            }
          }
        ].filter((c => {
          if (this.$setting.env == 'fhjy.lbl.morelink56.com' && c.dataIndex == 'fuel_surcharges') {
            return false
          }
          if (this.$setting.env == 'first-star.lbl.morelink56.com' && c.dataIndex == 'fuel_surcharges') {
            return false
          }
          if (this.$setting.env != 'szbyze.com' && c.dataIndex == 'unitPrice') {
            return false
          }
          return true
        }))
      },
      /* 验证是否通过 */
      validate_package() {
        return this.packages.some(item => {
          const {
            unit: {
              dims_unit,
              weight_unit
            }
          } = item
          Object.assign(item, {
            dims_unit,
            weight_unit
          });
          return !item.length || !item.width || !item.height || !item.weight
        })
      }
    },
    mounted() {
      this.query();
      this.addPackage();
    },
    methods: {
      /**
       * 查询基础信息
       */
      query() {
        Promise.all([
          this.$http.get('/admin/shipper/simple_list'),
          this.$http.get('/admin/order/freight_class'),
          this.$http.get(`/system_config/content/order_page_content`),
          this.$http.get(`/admin/system_config/list/basic`),
          this.$http.get(`/admin/currency/list?sort=sort&order=asc&page=1&limit=15`),
        ]).then(res => {
          if (res[0].data.code === 0) {
            this.shippers = res[0].data.data;

            if (this.shippers.length) {
              this.form.address.shipper_address_id = this.shippers[0].id;
            }

            // 定制判断
            if (this.$setting.env == 'fhjy.lbl.morelink56.com' || this.$setting.env == 'first-star.lbl.morelink56.com') this.form.address.shipper_address_id = 0
            if (this.$setting.env == 'hygj.lbl.morelink56.com') this.form.address.shipper_address_id = 0
          } else {
            this.$message.error(res[0].data.msg);
          }

          if (res[1].data.code === 0) {
            this.freight_class = res[1].data.data;
          } else {
            this.$message.error(res[1].data.msg);
          }

          if (res[2].data.code === 0) {
            this.custom_content = res[2].data.data;
          }

          if (res[3].data.code === 0) {
            var data = Object.assign({}, res[3].data.data);
            this.shipper = data.shipper === '1';
          }
          if (res[4].data.code === 0) {
            this.currency = res[4].data.data[1];
          }
        }).catch(e => {
          this.$message.error(e.message);
        });
      },
      /**
       * 添加箱
       */
      addPackage() {
        this.packages.push({
          id: `${(new Date()).getTime()}`,
          pieces: '1',
          packages_qty: '1',
          freight_class: '55',
          dims_unit: this.obj_all_unit.dims_unit,
          weight_unit: this.obj_all_unit.weight_unit,
          unit: this.obj_all_unit
        });
      },
      /**
       * 清空包裹
       */
      clearPackages() {
        this.form.packages = [];
        this.addPackage();
      },
      /**
       * 移除箱
       */
      removePackage(row) {
        if (this.packages.length > 1) {
          this.packages.splice(this.packages.indexOf(row), 1);

          this.$message.success('user_order.tips.delete_success');
        }
      },
      /**
       * 监视地址框粘贴地址自动识别转换
       */
      handlePaste(e) {
        var content = e.clipboardData.getData('text');
        var row = address.parse(content);

        this.$nextTick(() => {
          row && this.chooseAddress(row);
          !row && (this.address.name = content);
        });

        e.preventDefault();
      },
      /**
       * 选择地址
       */
      chooseAddress(row) {
        this.address.name = row.name;
        this.address.company_name = row.company_name;
        this.address.address1 = row.address1;
        this.address.address2 = row.address2;
        this.address.address3 = row.address3;
        this.address.city = row.city;
        this.address.state_code = row.state_code;
        this.address.postal_code = row.postal_code;
        this.address.country_code = row.country_code;
        this.address.phone_number = row.phone_number;

        this.$refs.address_form.clearValidate();
        this.test_rates();
      },
      /**
       * 查询FBA地址
       */
      queryFbaAddress(name) {
        this.query_address_loading = true;

        this.$http.get(`/admin/address_book/query_single_address/${name}`).then(res => {
          this.query_address_loading = false;

          if (res.data.code === 0) {
            this.chooseAddress(res.data.data);
          }
        }).catch(() => this.query_address_loading = false);
      },
      /**
       * 测试费用
       */
      test_rates: _.debounce(function() {
        // 焦点控制开关
        if (!this.onFoucsControl) {
          return
        }

        if (!this.$refs.address_form || !this.$refs.packages_form) {
          return;
        }

        // 包裹必填项
        if (this.validate_package) {
          return
        }

        if (test_rates_post.apiPost) {
          test_rates_post.cancel()
        }

        this.$refs.address_form.validate().then(() => {
          clearTimeout(this.rates_timer);

          this.rates_timer = setTimeout(() => {
            this.rates_loading = true;
            this.rates_result = {};
            this.error_code = '';

            if (this.system_config.allow_customs_data != '1' || !this.allow_customs_data) {
              this.form.items = [];
            }

            var postData = Object.assign({
              ship_method_id: this.rates_current_ship_method_id,
              shipper_address_id: this.address.shipper_address_id
            }, this.form);

            postData.shipper_address_id = postData.shipper_address_id + "";
            postData.ship_date = moment().format('YYYY-MM-DD');

            test_rates_post.apiPost = this.$http.post('/admin/order/rates', postData, {
              cancelToken: test_rates_post.get_token()
            }).then(res => {
              this.rates_loading = false;

              if (res.data.code === 0) {
                this.rates_current_ship_method_id = res.data.data.ship_method_id;
                this.rates_result = res.data.data;

                var current = this.rates_result.ship_methods.find(a => a.id == this.rates_result
                  .ship_method_id);

                this.rates_result.postal_code_type = current != null ? current.postal_code_type : 0;
              } else {
                this.error_code = res.data.msg;
              }
            }).catch(e => {
              this.rates_loading = false;
              if (e.message == 'cancel') {
                return
              }
              this.$message.error(e.message);
            }).finally(() => {
              test_rates_post.new_token();
            });
          }, 500);
        }).catch(() => {});
        this.onFoucsControl = false;
      }, 300),
      /**
       * Rates排序
       */
      sort_rates(a, b) {
        return (a.active ? a.amount : 999999) - (b.active ? b.amount : 999999);
      }
    },
    setup() {
      const obj = cachePostCode();
      const form = itemHook();
      const analyzePostalCode = AnalyzePostalCode({
        address_form: form.address_form,
        address: form.address,
        rules: form.rules
      });
      delete form.rules.value.phone_number;
      return {
        ...obj,
        ...form,
        ...analyzePostalCode
      }
    }
  }
